/**
 * @class DialogComponent
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2024 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */
import { Component } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
@Component({
    selector: 'nb-dialog-template',
    template: `
        <div class="container">
            <div class="info">
                <h1>
                    ¡Hola! Parece que estás enfrentando problemas de conexión.
                    No te preocupes, aquí tienes algunas sugerencias que podrían
                    ayudarte a solucionarlo:
                </h1>
                <ul>
                    <li>
                        <b>Reinicia tu router o módem.</b> A veces, simplemente
                        apagarlo y volverlo a encender puede solucionar
                        problemas de conexión.
                    </li>
                    <li>
                        <b>Verifica los cables.</b> Asegúrate de que todos los
                        cables estén bien conectados y en buen estado. Podrías
                        intentar desconectar y volver a conectar los cables para
                        asegurarte de que estén correctamente conectados.
                    </li>
                    <li>
                        <b>Reinicia tu dispositivo.</b> Si el problema persiste,
                        intenta reiniciar el dispositivo que estás utilizando
                        para conectarte. Apágalo durante unos segundos y luego
                        enciéndelo nuevamente.
                    </li>
                    <li>
                        <b>Verifica la configuración de red.</b> Asegúrate de
                        que la configuración de red de tu dispositivo esté bien
                        configurada. Verifica que estés conectado a la red
                        correcta y que no haya restricciones o bloqueos de
                        conexión.
                    </li>
                    <li>
                        <b
                            >Ponte en contacto con tu proveedor de servicios de
                            Internet.</b
                        >
                        Si has intentado todas estas sugerencias y el problema
                        aún persiste, es posible que haya un problema con tu
                        proveedor de servicios de Internet. Comunícate con ellos
                        para obtener ayuda adicional y asegúrate de informarles
                        sobre los pasos que ya has seguido.
                    </li>
                </ul>
            </div>
            <button id="modal-id">Cerrar</button>
        </div>
    `,
    styleUrls: ['./dialog.css'],
})
export class DialogComponent {
    dialogRef: NbDialogRef<any>;
  constructor(private dialogService: NbDialogService) {}  // eslint-disable-line
}
