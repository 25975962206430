import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
    {
        title: 'Dashboard',
        icon: 'shopping-cart-outline',
        link: '/pages/map',
        home: true,
    },
];
