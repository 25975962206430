<div *ngIf="this.loadingLogin">
	<ngx-app>Loading...</ngx-app>

	<style>
	  @-webkit-keyframes spin{
		0%{
		  transform:rotate(0)
		}
		100%{
		  transform:rotate(360deg)
		}
	  }
	  @-moz-keyframes spin{
		0%{
		  -moz-transform:rotate(0)
		}
		100%{
		  -moz-transform:rotate(360deg)
		}
	  }
	  @keyframes spin{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}
	  .spinner{position:fixed;top:0;left:0;width:100%;height:100%;z-index:1003;background: #FFFFFF;overflow:hidden}  
	  .spinner div:first-child{
		display:block;
		position:relative;
		left:50%;
		top:50%;
		width:150px;
		height:150px;
		margin:-75px 0 0 -75px;
		border-radius:50%;
		box-shadow:0 3px 3px 0 #2d3192;
		transform:translate3d(0,0,0);
		animation:spin 2s linear infinite
	  }  
	  .spinner div:first-child:after,
	  .spinner div:first-child:before{
		content:'';
		position:absolute;
		border-radius:50%
	  }  
	  .spinner div:first-child:before{
		top:5px;
		left:5px;
		right:5px;
		bottom:5px;
		box-shadow:0 3px 3px 0 #FF416C;
		-webkit-animation:spin 3s linear infinite;
		animation:spin 3s linear infinite
	  }  
	  .spinner div:first-child:after{
		top:15px;
		left:15px;
		right:15px;
		bottom:15px;
		box-shadow:0 3px 3px 0 #2d3192;
		animation:spin 1.5s linear infinite
	  }
	</style>
	<div id="nb-global-spinner" class="spinner">
	  <div class="blob blob-0"></div>
	  <div class="blob blob-1"></div>
	  <div class="blob blob-2"></div>
	  <div class="blob blob-3"></div>
	  <div class="blob blob-4"></div>
	  <div class="blob blob-5"></div>
	</div>
</div>

<div class="container" id="container" *ngIf="!this.loadingLogin">

	<div class="form-container sign-in-container">
		<form 
			[formGroup]="loginForm" 
			(ngSubmit)="login(loginForm.value)" action="#"
			(keypress.enter)="login(loginForm.value)" action="#">
			
			<span class="span-form-large" style="color: white">Ingresa con tu correo</span>

			<input 
				type="email" 
				placeholder="Email" 
				id="email-input-register"
				formControlName="email"/>
			
			<input 
				type="password" 
				placeholder="Password" 
				id="password-input-register"
				formControlName="pass"/>

			<button type="submit"
				(keyup.enter)="login(loginForm.value)">
				Inicia sesión
			</button>
			
			<span class="span-form" style="color: white">o inicia sesión con Google</span>

			<div class="" id="google-button"></div>

			<h3 class="forgot" (click)="openPasswordDialog()"><a>¿Olvidaste tu contraseña?</a></h3>

		</form>
	</div>

	<div class="overlay-container">
		<div class="overlay">
			<div class="overlay-panel overlay-right">
				<img class="logo" [src]="iconTrazapp()"/>
				<h2 class="title">Trazapp</h2>
			</div>
		</div>
	</div>

</div>