/**
 * @class AvatarService
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2024 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AvatarService {
    private tag: string = 'Avatar service';

    private jwt: string | null = null;

    private user: any = null;

    userAvatarStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    userAvatarURL: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(
        private logger: NGXLogger,// eslint-disable-line
    private router: Router) {// eslint-disable-line
        try {
            this.jwt = localStorage.getItem('jwt-trazapp');
            this.user = JSON.parse(localStorage.getItem('user'));
            this.getUserAvatar(this.user.id_usu);
        } catch (err) {
            this.logger.error('Ocurrió un error obteniendo el avatar usuario.');
        }
    }

    /**
     * Peticion para obtener el último ávatar almacenado por el usuario
     * @method  getUserAvatar
     * @param id id del usuario
     */
    getUserAvatar(id: number): void {
        this.userAvatarStatus.next(true);

        this.logger.info(
            `request to [${environment.pgrst_api}/rpc/user_avatars]...`
        );

        try {
            fetch(`${environment.pgrst_api}/rpc/user_avatars`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/octet-stream',
                    Authorization: `Bearer ${this.jwt}`,
                },
                body: JSON.stringify({ user_id: id }),
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        return await response.blob();
                    } else {
                        const json = await response.json();
                        throw new Error(
                            json && json.message
                                ? json.message
                                : response.statusText
                        );
                    }
                })
                .then((blob) => {
                    this.updateAvatarImage(blob);
                })
                .catch((ex) => {
                    this.updateAvatarImage(null);
                    this.logger.warn(
                        'Hubo un error intentando obtener el avatar del usuario!',
                        ex
                    );
                });
        } catch (ex) {
            this.updateAvatarImage(null);
        }
    }

    /**
     * Peticion para obtener el último ávatar almacenado según el id del usuario
     * @method  getUserAvatarById
     * @param id id del usuario
     */
    async getUserAvatarById(data: any, card: boolean) {
        const { user_id, alias: name, id } = data;

        if (card) {
            return this.getAvatarURL(null, name, null);
        }

        this.userAvatarStatus.next(true);

        this.logger.info(
            `request to [${environment.pgrst_api}/rpc/user_avatars]...`
        );

        try {
            if (JSON.parse(localStorage.getItem(`${id}`))?.url_avatar) {
                return this.getAvatarURL(
                    null,
                    name,
                    JSON.parse(localStorage.getItem(`${id}`))?.url_avatar
                );
            }
            const avatar = await fetch(
                `${environment.pgrst_api}/rpc/user_avatars`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        accept: 'application/octet-stream',
                        Authorization: `Bearer ${this.jwt}`,
                    },
                    body: JSON.stringify({ user_id }),
                }
            );
            const res = await avatar.blob();
            if (res.type !== 'image/jpg') {
                return this.getAvatarURL(null, name, null);
            }
            // return res;
            return this.getAvatarURL(res, name, null);
        } catch (ex) {
            this.logger.error(ex);
        }
    }

    /**
     * Actualiza el tag img donde se debe renderizar el avatar del usuario
     * @method updateAvatarImage
     * @param {blob} blob
     */
    private updateAvatarImage(blob: Blob | null): void {
        setTimeout(() => {
            if (blob) {
                const url = URL.createObjectURL(blob);
                this.userAvatarURL.next(url);
                if (localStorage.getItem('user-avatar')) {
                    localStorage.removeItem('user-avatar');
                }
                localStorage.setItem('user-avatar', url);
                return;
            }
            const url = this.getAvatar(this.getUserInitials(this.user.nom_usu));
            this.userAvatarURL.next(url);
        }, 200);
    }

    /**
     * Devuelve las iniciales del nombre y apellido del usuario
     * @method getUserInitials
     */
    private getUserInitials(name: string): string {
        if (!name) return;
        const str = `${name[0]}${name[1]}`;
        return str;
    }

    /**
     * Actualiza el tag img donde se debe renderizar el avatar del usuario
     * @method updateAvatarImage
     * @param {blob} blob
     */
    private getAvatarURL(
        blob: Blob | null,
        name: string,
        urlAvatar: string
    ): string {
        if (urlAvatar) {
            return urlAvatar;
        }
        if (blob) {
            return URL.createObjectURL(blob);
        }
        const url = this.getAvatar(this.getUserInitials(name));
        return url;
    }

    /**
     * Actualiza el tag img donde se debe renderizar el avatar del usuario
     * @method updateAvatar
     * @param {blob} blob
     */
    updateAvatar(url: string): void {
        setTimeout(() => {
            const imgElement = document.getElementById(
                'userAvatar'
            ) as HTMLImageElement;

            if (!imgElement) return;

            if (url) {
                imgElement.src = url;
            }
            this.userAvatarStatus.next(false);
        }, 300);
    }

    /**
     * Obtiene el ávatar del usuario a través de la api https://ui-avatars.com/api/
     * @method  getAvatar
     * @param name string con el nombre y apellido del usuario
     */
    private getAvatar(name: string) {
        const color = this.phraseColor(name);
        const avatarUrl = `${environment.avatarApi}/?background=${color}&length=2&color=fff&name=${name}&format=svg`;
        return encodeURI(avatarUrl);
    }

    /**
     * Asigna un color aleatorio para el background del método getAvatar.
     * @method  phraseColor
     */
    private phraseColor(phrase: any) {
        if (!phrase) return;
        const BG_COLORS = [
            '9ddbc9',
            '5b09d0',
            '8504db',
            'ab4bde',
            'ffa500',
            '9acd32',
            '3098b5',
            'debd3c',
            'bd3eef',
            '009eed',
            '71a897',
            'dfe3ab',
            '8cde91',
            '8184ef',
        ];

        let ascii = phrase.charCodeAt(0);
        while (ascii >= BG_COLORS.length) {
            ascii = Math.floor(ascii / BG_COLORS.length);
        }
        return BG_COLORS[ascii];
    }
}
