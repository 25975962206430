<div class="container">
    <div class="inputsContainer">
        <form 
            style="width: 100%; border-radius: 0.8em; padding-top: 2.5em;"
            [formGroup]="updatePasswordForm" 
            (keypress.enter)="confirmChanges(updatePasswordForm.value)" action="#"
        >
            <div class="profile-box">
                <h1>Restablecer contraseña</h1>
                <div class="user-box">
                    <input type="password" name="" required="" placeholder="Contraseña" formControlName="newpassword">
                </div>
                <div class="user-box">
                    <input type="password" name="" required="" placeholder="Confirmar contraseña" formControlName="confirmpassword">
                </div>
                <div class="buttons-container">
                    <button class="confirm-button" type="submit" (click)="confirmChanges(updatePasswordForm.value)">
                        <span class="button-text">Confirmar</span>
                    </button>
                    <button class="cancelButton" (click)="cancelChanges()">
                        <span class="button-text">Restablecer</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>