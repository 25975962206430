import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginFormComponent } from './@theme/components/auth/login-form.component';
import { PagesComponent } from './pages/pages.component';
import { SyncComponent } from './@theme/components/sync/sync.component';
import { UpdatePasswordComponent } from './@theme/components/update-password/updatePassword.component';
import { UpdateEmailComponent } from './@theme/components/update-email/updateEmail.component';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent,
        children: [
            {
                path: '',
                component: LoginFormComponent,
            },
            {
                path: 'sync',
                component: SyncComponent,
            },
            {
                path: 'update_password_view',
                component: UpdatePasswordComponent,
            },
            {
                path: 'update_user_email',
                component: UpdateEmailComponent,
            },
        ],
    },
    {
        path: 'pages',
        loadChildren: () =>
            import('./pages/pages.module').then((m) => m.PagesModule),
    },
    { path: '', redirectTo: 'pages', pathMatch: 'full' },
    { path: '**', redirectTo: '', pathMatch: 'full' },
];

const config: ExtraOptions = {
    useHash: false,
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
