/**
 * @class ToastService
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2024 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */

import { Injectable } from '@angular/core';
import {
    NbComponentStatus,
    NbGlobalPhysicalPosition,
    NbToastrService,
} from '@nebular/theme';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
  constructor(private toastService: NbToastrService) {} // eslint-disable-line

    private showToast(
        type: NbComponentStatus,
        title: string,
        body: string = null,
        ops: object = null
    ) {
        const titleContent = title ? title : '';
        const config = Object.assign(
            {
                status: type,
                destroyByClick: true,
                duration: 5000,
                hasIcon: true,
                position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
                preventDuplicates: true,
                toastClass: 'toast-class',
            },
            ops
        );

        this.toastService.show(body, titleContent, config);
    }

    public success(title: string, body: string | null) {
        if (!title) return;
        this.showToast('success', title, body);
    }

    public error(title: string, body: string | null) {
        if (!title) return;
        this.showToast('danger', title, body);
    }

    public warn(title: string, body: string | null) {
        if (!title) return;
        this.showToast('warning', title, body);
    }
}
