/**
 * @class ConnectionService
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2024 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
    NbComponentStatus,
    NbGlobalPhysicalPosition,
    NbToastrService,
} from '@nebular/theme';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ConnectionService {
    isOnline: boolean = true;
    networkStatus: BehaviorSubject<boolean> = new BehaviorSubject(true);
    apiStatus: BehaviorSubject<any> = new BehaviorSubject('');

  constructor(private toastService: NbToastrService) { // eslint-disable-line
        setInterval(() => {
            this.pingServer(environment.pgrst_api, (serviceOnline) => {
                if (this.isOnline === serviceOnline) return;
                this.networkStatus.next(serviceOnline);
                this.changeStatus(serviceOnline);
                this.isOnline = serviceOnline;
            });
        }, 1000 * 5);
    }

    pingServer(url: string, callback: Function) {
        const me = this;
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.onreadystatechange = function () {
            me.apiStatus.next(xhr.response);
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    callback(true);
                } else {
                    callback(false);
                    me.apiStatus.next(xhr.response);
                }
            }
        };
        xhr.send();
    }

    private showToast(
        type: NbComponentStatus,
        title: string,
        body: string = null,
        ops: object = null
    ) {
        const titleContent = title ? title : '';
        const config = Object.assign(
            {
                status: type,
                destroyByClick: true,
                duration: 5000,
                hasIcon: true,
                position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
                preventDuplicates: false,
            },
            ops
        );
        this.toastService.show(body, titleContent, config);
    }

    private changeStatus(e: boolean) {
        if (e && !this.isOnline) {
            this.showToast('success', '¡Conexión a internet restablecida!');
            return;
        }
        if (!e) {
            this.showToast('danger', '¡Sin conexión a internet!');
        }
    }
}
