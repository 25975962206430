import { Component } from '@angular/core';

@Component({
    selector: 'ngx-footer',
    styleUrls: ['./footer.component.scss'],
    template: ``,
})
export class FooterComponent {}

//ICONOS RRSS:
// <div class="socials">
// <a href="https://api.whatsapp.com/send?phone=+5804123358806&text=Deseo%20informaci%C3%B3n%20sobre%20la%20plataforma%20Trazapp" target="_blank" class="ione ion-social-whatsapp"></a>
// <a href="https://www.facebook.com/sigisca" target="_blank" class="ione ion-social-facebook"></a>
// <a href="https://www.instagram.com/sigisca/" target="_blank" class="ione ion-social-instagram"></a>
// <a href="https://www.youtube.com/@SIGISCA" target="_blank" class="ione ion-social-youtube"></a>
// </div>
