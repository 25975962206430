<div class="header-container" *ngIf="this.loggedIn">
  <div class="logo-container">
    <a class="logo" href="#" (click)="navigateHome()">
      <img [src]="iconTrazapp()" title="Trazapp" style="width: 50px; height: 50px;">
      <span>Trazapp</span>
    </a>
  </div>
  <div [className]="isConnected ? 'dot' : 'dot-disconnected'">
  </div>
</div>

<button class="logoutButton logoutButton--dark" (click)="navigateHome()" *ngIf="showReturn">
  <span class="button-text">
    <span>Regresar</span>
    <nb-icon icon="corner-up-left-outline"></nb-icon>
  </span>
</button>

<nb-icon 
  icon="wifi-off-outline" 
  style="color: red; position: absolute; right: 250px; top: 30%; cursor: pointer" 
  *ngIf="!connectionStatus"
  (click)="connectionWarning()"
></nb-icon>

<div class="header-container" *ngIf="this.loggedIn">

  <nb-actions size="small">

    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user
       
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.ema_usu"
        [picture]="photo">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
