import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FilePondModule } from 'ngx-filepond';
import {
    NbDatepickerModule,
    NbDialogModule,
    NbMenuModule,
    NbSidebarModule,
    NbToastrModule,
    NbTooltipModule,
    NbWindowModule,
} from '@nebular/theme';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginFormComponent } from './@theme/components/auth/login-form.component';
import { SyncComponent } from './@theme/components/sync/sync.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogComponent } from './@theme/components/templates/dialog/dialog.component';
import { PasswordDialogComponent } from './@theme/components/templates/update-password-dialog/passwordDialog.component';
import { UpdatePasswordComponent } from './@theme/components/update-password/updatePassword.component';
import { EmailDialogComponent } from './@theme/components/templates/update-email-dialog/emailDialog.component';
import { UpdateEmailComponent } from './@theme/components/update-email/updateEmail.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

@NgModule({
    declarations: [
        AppComponent,
        LoginFormComponent,
        SyncComponent,
        DialogComponent,
        PasswordDialogComponent,
        UpdatePasswordComponent,
        EmailDialogComponent,
        UpdateEmailComponent,
    ],
    imports: [
        NbTooltipModule,
        RouterModule.forRoot([]),
        InfiniteScrollModule,
        FilePondModule,
        LeafletModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        NbEvaIconsModule,
        NbDialogModule.forRoot(),
        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbWindowModule.forRoot(),
        NbToastrModule.forRoot(),
        CoreModule.forRoot(),
        ThemeModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),
        LoggerModule.forRoot({
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.ERROR,
        }),
    ],
    providers: [provideAnimationsAsync()],
    bootstrap: [AppComponent],
})
export class AppModule {}
