/**
 * @class SyncComponent
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2024 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */

import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import {
    ResultTokenRequest,
    UserSuccessFetch,
} from '../../../@core/data/users';
import { EmitterService } from '../../../@core/mock/emitter.service';
import { NGXLogger } from 'ngx-logger';
@Component({
    selector: 'app-custom-sync',
    templateUrl: './sync.component.html',
    styleUrls: ['./sync.component.css', './syncLoader.component.scss'],
})
export class SyncComponent implements OnInit {
    /**
     * Clave de emitter
     */
    key: string;

    /**
     * Canal para emitter
     */
    channel: string;

    public message: string;
    keygenCounter: number | null;
    jwt: any;
    token: string | null;
    apiUrl: string = environment.pgrst_api;
    user: UserSuccessFetch | null;
    urlParams = new URLSearchParams(window.location.search);
    clientName: string;
    trackingSettings: any;
    isLoading: boolean = true;
    thirdJwt: string;
    isFailed: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,  // eslint-disable-line
        private emitter: EmitterService,  // eslint-disable-line
        private ngZone: NgZone,  // eslint-disable-line
        private route: ActivatedRoute,  // eslint-disable-line
        private router: Router,  // eslint-disable-line
        private logger: NGXLogger  // eslint-disable-line
    ) {
        this.route.queryParamMap.subscribe((query: any) => {
            this.thirdJwt = query['params'].jwt;
        });
        this.jwt = this.thirdJwt;
        if (this.thirdJwt) {
            localStorage.clear();
            localStorage.removeItem('jwt-trazapp');
            if (localStorage.getItem('user')) {
                localStorage.removeItem('user');
                window.location.reload();
            }
        }
        if (!this.jwt) {
            this.jwt = localStorage.getItem('jwt-trazapp');
        }
        this.clientName = localStorage.getItem('trazapp-client-name');
        if (!this.jwt) {
            setTimeout(() => {
                this.ngZone.run(() => {
                    this.router.navigate(['/pages/map']);
                });
            }, 100);
        }
    }

    ngOnInit(): void {
        Object.assign(this, this.parseURLParams());

        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.jwt) {
                this.jwt = params.jwt;
            }
        });

        this.message = this.emitter.msg;

        setTimeout(() => {
            this.addOverlayClass();
        }, 400);

        this.sync();
    }

    //Método para generar la animación en el componente.
    addOverlayClass(): void {
        const isMobile = window.innerWidth <= 425 ? true : false;
        if (isMobile) return;
        const baseElement = document.querySelector('.base');
        const overlayElement = baseElement.cloneNode(true) as HTMLElement;
        if (!overlayElement) return;
        overlayElement.classList.add('overlay');
        document.querySelector('.landing').appendChild(overlayElement);
    }

    getToken(): string {
        const token = localStorage.getItem('jwt-trazapp');
        return token;
    }

    //Método para retornar el logo renderizado en la navbar
    iconTrazapp(): string {
        return 'assets/icons/trazapp_full_size.png';
    }

    /**
     * Crea una nueva solicitud de sincronización
     */
    newSync(): void {
        this.clean();
        this.sync();
    }

    /**
     * Ejecuta una petición para sincronizar un dispostivo de trazapp móvil
     */
    sync(): void {
        this.logger.info(
            `request to [${this.apiUrl}/rpc/device_sync_request]...`
        );
        this.loading(true);
        this.syncFailed(true);
        try {
            fetch(`${this.apiUrl}/rpc/device_sync_request`, {
                method: 'POST',
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.jwt}`,
                },
                body: JSON.stringify({
                    _in_tracking_settings: Object.assign(
                        {},
                        {},
                        this.trackingSettings
                    ),
                }),
            })
                .then(async (response) => await response.json())
                .then((data) => {
                    if (data && data.message) {
                        this.syncFailed(false);
                        throw new Error(data.message);
                    } else if (data && data.id) {
                        this.getQRImage(data.id);
                        if (data.emitterChannel) {
                            localStorage.setItem(
                                'trazapp-sync-emitterChannel',
                                data.emitterChannel
                            );
                            this.emitterKeygen(data.emitterChannel);
                        }
                        localStorage.setItem('trazapp-sync-id', data.id);
                    } else {
                        this.logger.warn(
                            'Ocurrió un error intentando de obtener la data.'
                        );
                    }
                })
                .catch((ex) => {
                    this.processError(ex);
                })
                .finally(() => {
                    this.loading(false);
                });
        } catch (ex) {
            this.processError(ex);
        }
    }

    /**
     * Gestiona repetidamente la solicitud del  channel key
     */
    emitterKeygen(emitterChannel: string) {
        if (!this.keygenCounter) {
            this.keygenCounter = 1000 * 0.5;
        } else if (this.keygenCounter > 30000) {
            emitterChannel = null;
            return;
        }

        setTimeout(() => {
            this.requestChannelKey(emitterChannel).then((key) => {
                if (key) {
                    this.emitterSubscribe(key, emitterChannel);
                } else {
                    this.keygenCounter *= 2;
                    this.emitterKeygen(emitterChannel);
                }
            });
        }, this.keygenCounter);
    }

    /**
     * Handler emitter subscribe && messages
     *
     * @param {string} key
     * @param {string} channel
     */
    emitterSubscribe(key: string, channel: string): void {
        if (this.emitter) {
            this.emitter.channelConnect(this.jwt, { key, channel });
        }
    }
    /**
     * Obtiene el key para determinado channel
     * @param {string} channelName
     * @returns Promise
     */
    requestChannelKey(channelName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const query = new URLSearchParams({
                select: 'ts,response->>key,response->>channel',
                'response->>channel': `like.${channelName}*`,
                order: 'ts.desc',
                limit: '1',
            });
            this.logger.info(`request to [${this.apiUrl}/rpc/emitterkeys]...`);
            fetch(`${this.apiUrl}/rpc/emitterkeys?${query}`, {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${this.jwt}`,
                },
            })
                .then((response) => response.json())
                .then((keygens) => {
                    resolve(keygens[0].key);
                })
                .catch((ex) => {
                    this.logger.warn(ex);
                    reject(ex);
                });
        });
    }

    /**
     * Obtiene la imagen QR asociada a la solicitud de sincronización
     */
    getQRImage(id: string): void {
        this.logger.info(`request to [${this.apiUrl}/rpc/get_qr_image]...`);

        this.loading(true);

        try {
            fetch(
                `${this.apiUrl}/rpc/get_qr_image?_in_device_sync_requests_id=${id}`,
                {
                    method: 'GET',
                    headers: {
                        accept: 'application/octet-stream',
                        Authorization: `Bearer ${this.jwt}`,
                    },
                }
            )
                .then(async (response) => {
                    if (response.status === 200) {
                        return await response.blob();
                    } else {
                        const json = await response.json();
                        throw new Error(
                            json && json.message
                                ? json.message
                                : response.statusText
                        );
                    }
                })
                .then((blob) => {
                    this.updateQRView(blob);
                    setTimeout(() => {
                        this.isFailed = false;
                    }, 200);
                })
                .catch((ex) => {
                    this.processError(ex);
                })
                .finally(() => {
                    this.loading(false);
                });
        } catch (ex) {
            this.processError(ex);
        }
    }

    /**
     * Actualiza las vistas necesarias mientras se esta en proceso alguna petición
     * @param {boolean} loading
     * @returns void
     */
    loading(loading: boolean): void {
        this.isLoading = loading;
    }

    /**
     * Actualiza la vista donde se muestra el qr
     * @param {blob} blob
     */
    updateQRView(blob: Blob | null) {
        setTimeout(() => {
            const imgElement = document.getElementById(
                'trazappQRImg'
            ) as HTMLImageElement;

            if (!imgElement) {
                return;
            }

            if (blob) {
                imgElement.src = URL.createObjectURL(blob);
                return;
            }
            imgElement.src = 'assets/icons/sync_failed.png';
            imgElement.setAttribute('style', 'width: 200px; height: 200px');
        }, 300);
    }

    /**
     * Muestra un mensaje
     * @param {string} msg
     */
    showError(msg: string): void {
        const x = document.getElementById('snackbar');
        x.className = 'show';
        x.innerHTML = msg;
        setTimeout(() => {
            x.className = x.className.replace('show', '');
        }, 3000);
    }

    /**
     * Limpia los datos locales
     */
    clean(): void {
        this.jwt = null;
        this.clientName = null;
        localStorage.removeItem('trazapp-jwt');
        localStorage.removeItem('trazapp-sync-id');
        localStorage.removeItem('trazapp-client-name');
    }

    /**
     * Elimina atributos nulos de un objeto javascript
     * @param {object} obj
     * @returns
     */
    removeNullFields(obj: any): void {
        // Iterate over the object's properties
        for (const [key, value] of Object.entries(obj)) {
            // Check if the value is null
            if (value === null) {
                // Remove the property from the object
                delete obj[key];
            }
        }
        return obj;
    }

    /**
     * Intenta conseguir en la url parámetros asoiados con el traking
     */
    parseURLParams(): ResultTokenRequest | {} {
        const urlParams = new URLSearchParams(window.location.search);
        let result = {};
        let params = {
            jwt: urlParams.get('jwt'),
            /** @TODO  Evaludar que tan conveniente es recibir clientID y accessToken*/
            // clientID: urlParams.get('clientID'),
            // accessToken: urlParams.get('accessToken'),
            trackingSettings: this.removeNullFields({
                trackingURL: urlParams.get('trackingURL'),
                angle: urlParams.get('angle'),
                distance: urlParams.get('distance'),
                traceRate: urlParams.get('traceRate'),
                accuracy: urlParams.get('accuracy'),
                offlineBuffer: urlParams.get('offlineBuffer'),
            }),
        };

        try {
            Object.assign(
                result,
                {
                    apiUrl: environment.pgrst_api,
                    clientID: environment.client_id_request,
                    accessToken: environment.access_token_request,
                },
                this.removeNullFields(params)
            );
        } catch (ex) {
            this.logger.warn(ex.message);
        }
        return result;
    }

    /**
     * Excepto exception
     */
    processError(ex: any): void {
        const msg = ex && ex.message ? ex.message : ex;
        this.logger.error(`Error: ${msg}`);
        this.clean();
    }

    /**
     * Obtiene la data el usuario almacenada en el localstorage
     */
    getUserData(): void {
        const user = JSON.parse(localStorage.getItem('user'));
        this.user = user;
    }

    refreshQR(): void {
        window.location.reload();
    }

    syncFailed(firstTime: boolean): void {
        setTimeout(() => {
            const audio = new Audio('assets/audio/failed.ogg');
            const imgElement = document.getElementById(
                'trazappQRImg'
            ) as HTMLImageElement;
            if (!audio || !imgElement) return;
            if (imgElement) {
                // imgElement.src = 'assets/icons/loading-spinner.gif';

                // imgElement.classList.remove("qr-code");
                // imgElement.classList.add("qr-code-without-border");
                this.isFailed = true;
                if (firstTime) return;
                imgElement.classList.add('flip-animation');
            }
            const playAudio = () => {
                audio.play();
            };
            if (firstTime) return;
            playAudio();
        }, 200);
    }
}
