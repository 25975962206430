/**
 * @class PasswordDialogComponent
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2024 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */

import { Component } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
@Component({
    selector: 'nb-dialog-template',
    template: `
        <div class="container">
            <div class="info">
                <h1>
                    Estás a un paso de solicitar la actualización de tu
                    contraseña...
                </h1>
                <p>
                    A continuación recibirás un correo electrónico para
                    continuar con el restablecimiento de tu constraseña.
                </p>
                <ul>
                    <li>
                        <b
                            >Chequea la bandeja de entrada de tu correo
                            electrónico.</b
                        >
                        Es probable que el correo electrónico lo recibas en la
                        carpeta de &quot;Spam&quot; o &quot;No deseados&quot;.
                    </li>
                    <li>
                        <b
                            >Ingresa al enlace que te figura en el cuerpo del
                            mail</b
                        >.
                    </li>
                </ul>
            </div>
            <div class="buttons-container">
                <button id="update-password">Aceptar</button>
                <button id="close-dialog">Cancelar</button>
            </div>
        </div>
    `,
    styleUrls: ['./passwordDialog.css'],
})
export class PasswordDialogComponent {
    dialogRef: NbDialogRef<any>;
  constructor(private dialogService: NbDialogService) {}  // eslint-disable-line
}
