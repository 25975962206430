/**
 *
 * @class ProfileComponent
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2023 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */

import { AfterViewInit, Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import {
    FieldError,
    UserFetch,
    UserSuccessFetch,
} from '../../../@core/data/users';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import {
    NbComponentStatus,
    NbGlobalPhysicalPosition,
    NbToastrService,
} from '@nebular/theme';
import { LoadingService } from '../../../@core/mock/loading.service';
import { NGXLogger } from 'ngx-logger';
import { PasswordDialogComponent } from '../templates/recover-password-dialog/passwordDialog.component';

declare const google: any;
@Component({
    selector: 'app-custom-login',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent implements AfterViewInit {
    dialogRef: NbDialogRef<any>;

    inputValue: string | null = null;

    emitterInstance: any;

    googleError: boolean;

    googleTries: number = 0;

    loadingLogin: boolean = false;

    loginForm: FormGroup;

    error: string = '';

    token: string;
    requestAccessToken: any = null;

    constructor(
    private toastrService: NbToastrService, // eslint-disable-line
    private router: Router, // eslint-disable-line
    private formBuilder: FormBuilder, // eslint-disable-line
    private ngZone: NgZone, // eslint-disable-line
    private loading: LoadingService, // eslint-disable-line
    private logger: NGXLogger, // eslint-disable-line
    private dialogService: NbDialogService // eslint-disable-line
    ) {
        this.loading.hardinessStatus.subscribe((e) => {
            this.logger.info(`Seteando en ${e} el spinner de carga.`);
            this.loadingLogin = e;
        });

        this.loginForm = this.formBuilder.group({
            pass: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
        });

        if (
            localStorage.getItem('user') &&
            localStorage.getItem('jwt-trazapp')
        ) {
            this.redirect();
        }

        fetch(`${environment.pgrst_api}/rpc/request_access`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify({
                _client_id: environment.client_id_request,
                _access_token: environment.access_token_request,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.message) {
                    throw new Error(data.message);
                } else if (data && data.token) {
                    if (this.requestAccessToken !== null) return;
                    this.requestAccessToken = data.token;
                }
            })
            .catch((ex) => {
                this.logger.error(ex);
                this.logger.fatal(ex.message);
                this.showToast('danger', 'Algo salio mal!');
            });
    }

    ngAfterViewInit() {
        this.prepareGoogleLogin();
    }

    redirect(): void {
        this.ngZone.run(() => {
            this.router.navigate(['/pages/map']);
        });
    }

    /**
     * Método para despleglar el diálogo para el cambio de contraseña.
     * @method openPasswordDialog
     */
    openPasswordDialog() {
        this.dialogRef = this.dialogService.open(PasswordDialogComponent, {
            hasScroll: true,
        });
        this.passwordUpdate();
        this.closePasswordDialog();
        this.dialogRef.onClose.subscribe(() => {
            this.logger.info('Modal cambio de contraseña cerrada!');
        });
    }

    /**
     * Método para cerrar el diálogo para el cambio de contraseña.
     * @method closePasswordDialog
     */
    closePasswordDialog() {
        const button = document.getElementById('close-dialog');
        button.addEventListener('click', () => {
            this.dialogRef.close();
        });
    }

    /**
     * Método para confirmar actualización de contraseña
     * @method validateEmail
     */
    validateEmail(): boolean {
        const input = document.getElementById(
            'email_input'
        ) as HTMLInputElement;
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!input) return false;
        if (input && input.value) {
            if (!regex.test(input.value)) {
                this.showToast(
                    'warning',
                    'Debes ingresar un correo electrónico válido.'
                );
                return false;
            }
            this.inputValue = input.value;
            return true;
        } else if (!input.value) {
            this.showToast('warning', 'Ingresa tu correo electrónico.');
            return false;
        } else {
            return false;
        }
    }

    /**
     * Método para confirmar actualización de contraseña
     * @method passwordUpdate
     */
    passwordUpdate() {
        const button = document.getElementById('update-password');
        button.addEventListener('click', () => {
            if (this.validateEmail()) {
                this.logger.info(
                    'Inicializando petición para actualización de contraseña...'
                );
                fetch(`${environment.pgrst_api}/rpc/update_password_request`, {
                    method: 'POST',
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: this.inputValue }),
                })
                    .then(async (res) => {
                        const respuesta = await res.json();
                        if (res.status === 200) {
                            this.logger.info(
                                'Solicitud de cambio de contraseña enviada correctamente!'
                            );
                            this.showToast(
                                'success',
                                'Solicitud para restablecer contraseña enviada correctamente.'
                            );
                        }
                        if (
                            respuesta &&
                            respuesta.message &&
                            respuesta.message === 'invalid mail'
                        ) {
                            this.showToast(
                                'danger',
                                '¡Ups! ¡Ocurrió un error!',
                                'El correo electrónico que ingresaste para recuperar tu contraseña es inválido.'
                            );
                            this.logger.warn(
                                'El email ingresado es inválido.',
                                respuesta
                            );
                            return;
                        }
                    })
                    .catch((err) => {
                        this.logger.error(
                            'Ocurrió un error modificando los datos del usuario.',
                            err
                        );
                        this.showToast(
                            'danger',
                            'Ups! Ocurrió un error intentando procesar tu solicitud para restablecer tu contraseña'
                        );
                    })
                    .finally(() => {
                        this.dialogRef.close();
                    });
            }
        });
    }

    /**
     * Prepara los elementos para el login con google
     */
    async prepareGoogleLogin() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) return;

        try {
            if (!google) {
                await this.googleRetry();
            }
            // @ts-ignore
            // window.onload =  async () => {

            setTimeout(async () => {
                await google.accounts.id.initialize({
                    client_id: environment.googleIdClient,
                    callback: this.handleCredentialResponse.bind(this),
                    auto_select: false,
                    cancel_on_tap_outside: true,
                    scope: 'profile email https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.addresses.read',
                    context: 'signin',
                    size: 'large',
                });
                this.logger.info('Renderizando botón de login con google!');
                // @ts-ignore
                await google.accounts.id.renderButton(
                    document.getElementById('google-button'),
                    {
                        type: 'standard',
                        theme: 'filled_blue',
                        size: 'large',
                        texto: 'signin_with',
                    }
                );
            }, 300);

            this.googleTries = 0;
            // @ts-ignore
            // await google.accounts.id.prompt((notification: PromptMomentNotification) => {});
            // };
        } catch (ex) {
            this.logger.warn(
                'Algo salió mal con el login de Google!',
                ex.message
            );
            await this.googleRetry();
        }
    }

    async googleRetry() {
        if (this.googleTries >= 3) return;
        this.googleTries++;
        this.logger.warn('Reintentando el login de google!');
        try {
            const googleScript = document.getElementById('googleLoginScript');
            googleScript?.parentNode?.removeChild(googleScript);
            setTimeout(async () => {
                this.logger.info(
                    'Creando nuevamente el script para logueo con google!'
                );
                const newScript = document.createElement('script');
                newScript.src = 'https://accounts.google.com/gsi/client';
                newScript.async = true;
                newScript.defer = true;
                newScript.id = 'googleScript';
                document.head.appendChild(newScript);
                await this.prepareGoogleLogin();
            }, 300);
        } catch (err) {
            this.logger.error(
                'Ocurrió un error generando nuevamente el script de login de google!',
                err
            );
        }
    }

    /**
     * Obtiene el token actual de sessión
     * @returns
     */
    getCurrentToken() {
        return localStorage.getItem('jwt-trazapp');
    }

    /**
     * Método que captura la respuesta de la petición de inicio de sesión con Google
     * @param response
     */
    async handleCredentialResponse(response: any) {
        const res = await response;
        const data = {
            _google_client_id: res.client_id,
            _google_token: res.credential,
        };
        await this.loginRequest('/rpc/google_login', data);
    }

    /**
     * Método para retornar el logo renderizado en la navbar
     * @returns
     */
    iconTrazapp(): string {
        return 'assets/icons/trazapp_full_size.png';
    }

    /**
     * Método para cerrar sesión
     */
    signOut(): void {
        // this.authService.signOut();
    }

    /**
     * Método que realiza el login convencional.
     * Evalua que no existan errores en el formulario con la función recursiva getFormErrors(),
     * De existir errores en el mismo, retorna
     * @param _value
     * @returns
     */
  async login(_value): Promise<UserFetch | UserSuccessFetch | void> { // eslint-disable-line
        const errors: FieldError[] = [];
        this.getFormErrors(this.loginForm, '', '', errors);

        if (errors.length) {
            return;
        }
        fetch(`${environment.pgrst_api}/rpc/request_access`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                _client_id: environment.client_id_request,
                _access_token: environment.access_token_request,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.message) {
                    throw new Error(data.message);
                } else if (data && data.token) {
                    this.requestAccessToken = data.token;
                    this.loginRequest('/rpc/login', {
                        email: this.loginForm.get('email').value,
                        pass: this.loginForm.get('pass').value,
                    });
                }
            })
            .catch((ex) => {
                this.logger.warn(ex.message);
                this.showToast('danger', 'Algo salio mal!');
            });
    }

    /**
     * Método para limpiar el formulario
     */
    cleanForm(): void {
        this.loginForm.reset();
    }

    /**
     * Ejecuta la petición de login
     * @param data
     * @param endpoint
     */
    async loginRequest(endpoint: string, data: any) {
        const api = environment.pgrst_api;
        this.loading.setLoader(true);
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
                Authorization: `Bearer ${this.requestAccessToken}`,
            },
            body: JSON.stringify(data),
        };

        fetch(`${api}${endpoint}`, params)
            .then((response) => response.json())
            .then((data) => {
                if (data.token) {
                    localStorage.setItem('jwt-trazapp', data.token);
                    this.loginSuccess(data);
                } else if (data.message) {
                    this.showToast('danger', 'Algo salio mal!', data.message);
                }
            })
            .catch((ex) => {
                this.logger.error(ex.message);
                this.showToast('danger', 'Algo salio mal!');
            })
            .finally(() => {
                setTimeout(() => {
                    this.loading.setLoader(false);
                }, 5000);
            });
    }

    /**
     * Método recursiva que se encarga de evaluar los errores en el formulario del login,
     * Retorna un arreglo con dichos errores, de no existir, retorna un arreglo vacío
     * @param control
     * @param formGroupName
     * @param fieldName
     * @param errors
     */
    getFormErrors(
        control: AbstractControl,
        formGroupName: string,
        fieldName: string,
        errors: FieldError[]
    ) {
        if (control instanceof FormGroup) {
            Object.keys(control.controls).forEach((controlName) => {
                let formControl = control.get(controlName);
                if (formControl) {
                    let fGroupName = formGroupName + '-' + controlName;
                    this.getFormErrors(
                        formControl,
                        fGroupName,
                        controlName,
                        errors
                    );
                }
            });
        }

        if (control instanceof FormArray) {
            control.controls.forEach((fControl: AbstractControl, index) => {
                let fGroupName = formGroupName + '-' + index;
                this.getFormErrors(fControl, fGroupName, fieldName, errors);
            });
        }

        if (control.errors) {
            Object.keys(control.errors).forEach((errorCode) => {
                let title = fieldName;

                errors.push({
                    formGroupName: formGroupName,
                    fieldName: fieldName,
                    errorCode: errorCode,
                });

                switch (fieldName) {
                    case 'email':
                        fieldName = 'Correo';
                        break;

                    case 'pass':
                        fieldName = 'Clave de usuario';
                        break;

                    default:
                        break;
                }

                switch (errorCode) {
                    case 'required':
                        title = `${fieldName} es requerido!`;
                        break;

                    case 'email':
                        title = 'Formato de correo invalido!';
                        break;

                    default:
                        break;
                }

                this.showToast('warning', title, '', { duration: 1000 * 5 });
            });
        }
    }

    /**
     *
     * @param data
     */
    loginSuccess(data: any): void {
        localStorage.setItem('user', JSON.stringify(data));
        this.redirect();
    }

    /**
     * Muestra un toast
     * @param type NbComponentStatus
     * @param title string
     * @param body string
     */
    private showToast(
        type: NbComponentStatus,
        title: string,
        body: string = null,
        ops: object = null
    ) {
        const titleContent = title ? title : '';
        const config = Object.assign(
            {
                status: type,
                destroyByClick: true,
                duration: 5000,
                hasIcon: true,
                position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
                preventDuplicates: false,
            },
            ops
        );

        this.toastrService.show(body, titleContent, config);
    }
}
