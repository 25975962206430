import { Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'ngx-one-column-layout',
    styleUrls: ['./one-column.layout.scss'],
    template: `
        <nb-layout windowMode>
            <nb-layout-header fixed *ngIf="user">
                <ngx-header></ngx-header>
            </nb-layout-header>

            <nb-layout-column>
                <ng-content select="router-outlet"></ng-content>
            </nb-layout-column>
        </nb-layout>
    `,
})
export class OneColumnLayoutComponent {
    user: any;

    constructor(
    private logger: NGXLogger  // eslint-disable-line
    ) {
        try {
            this.user = JSON.parse(localStorage.getItem('user'));
        } catch (ex) {
            this.logger.warn(ex.message);
        }
    }
}
