/**
 * @class HeaderComponent
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2024 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
    NbComponentStatus,
    NbGlobalPhysicalPosition,
    NbToastrService,
} from '@nebular/theme';
import {
    NbMediaBreakpointsService,
    NbMenuService,
    NbThemeService,
} from '@nebular/theme';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { EmitterService } from '../../../@core/mock/emitter.service';
import { ConnectionService } from '../../../@core/mock/connection.service';
import { NGXLogger } from 'ngx-logger';
import { AvatarService } from '../../../@core/mock/avatar.service';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();

    jwt: string | null = null;

    isConnected: boolean = false;

    connectionStatus: boolean;

    userPictureOnly: boolean = false;

    showReturn: boolean = false;

    photo: string | any = '';

    user = null;

    loggedIn: boolean = this.user != null;

    hideButton: boolean = true;

    themes = [
        {
            value: 'default',
            name: 'Light',
        },
        {
            value: 'dark',
            name: 'Dark',
        },
    ];

    currentTheme = 'default';

    userMenu = [
        { title: 'Ayuda', tag: 'help' },
        { title: 'Perfil', tag: 'profile' },
        { title: 'Cerrar sesión', tag: 'cs' },
    ];

    constructor(
    private menuService: NbMenuService,  // eslint-disable-line
    private themeService: NbThemeService,  // eslint-disable-line
    private breakpointService: NbMediaBreakpointsService,  // eslint-disable-line
    private router: Router,  // eslint-disable-line
    private emitter: EmitterService, // eslint-disable-line
    private ngZone: NgZone, // eslint-disable-line
    private connection: ConnectionService, // eslint-disable-line
    private toastService: NbToastrService, // eslint-disable-line
    private logger: NGXLogger, // eslint-disable-line
    private avatarService: AvatarService // eslint-disable-line
    ) {
        try {
            this.emitter.emitterStatus.pipe().subscribe((ev) => {
                this.isConnected = ev === 1 ? true : false;
            });
            this.connection.networkStatus.subscribe((e) => {
                this.connectionStatus = e;
                if (!e) {
                    this.emitter.disconnect();
                } else {
                    this.emitter.emitterStatus.next(1);
                }
            });
            this.avatarService.userAvatarURL.subscribe((msg) => {
                if (msg !== '') {
                    this.photo = msg;
                }
            });
            this.user = JSON.parse(localStorage.getItem('user'));
            this.jwt = localStorage.getItem('jwt-trazapp');
            if (!this.user) {
                this.loggedIn = false;
            } else {
                this.loggedIn = true;
            }
        } catch (ex) {
            this.logger;
            this.logger.warn(ex.message);
        }

        if (
            this.loggedIn === false ||
            (this.user === null && this.router.url !== 'pages/privacy-policies')
        ) {
            this.hideButton = false;
        }
        if (this.router.url === 'pages/privacy-policies') {
            this.hideButton = false;
        }
        if (this.router.url === '/sync') {
            this.showReturn = true;
        }
    }

    ngOnInit() {
        this.currentTheme = this.themeService.currentTheme;

        const { xl } = this.breakpointService.getBreakpointsMap();
        this.themeService
            .onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$)
            )
            .subscribe(
                (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
            );

        this.themeService
            .onThemeChange()
            .pipe(
                map(({ name }) => name),
                takeUntil(this.destroy$)
            )
            .subscribe((themeName) => (this.currentTheme = themeName));

        this.menuService.onItemClick().subscribe((data) => {
            this.onMenuClick(data);
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    changeTheme(themeName: string) {
        this.themeService.changeTheme(themeName);
    }

    iconTrazapp(): string {
        return 'assets/icons/logo_trazapp.png';
    }

    navigateHome(): boolean {
        this.ngZone.run(() => {
            this.router.navigate(['pages/map']);
            // setTimeout(() => {
            //     window.location.reload();
            // }, 200);
        });
        return false;
    }

    onSignOut(): void {
        localStorage.clear();
        this.ngZone.run(() => {
            this.router.navigate(['/']);
            // setTimeout(() => {
            //     window.location.reload();
            // }, 100);
        });
    }

    onHelpButton(): void {
        this.logger.info('Redirigiendo al panel de ayuda.');
        this.ngZone.run(() => {
            this.router.navigate(['/pages/help']);
        });
    }

    onProfile(): void {
        this.logger.info('Redirigiendo al panel de perfil.');
        this.ngZone.run(() => {
            this.router.navigate(['/pages/profile']);
        });
    }

    onMenuClick(event) {
        switch (event.item.tag) {
            case 'help':
                this.onHelpButton();
                break;
            case 'cs':
                this.onSignOut();
                break;
            case 'profile':
                this.onProfile();
                break;
        }
    }

    /**
     * Método para retornar el icono de return en el boton de navbar
     * @returns
     */
    iconReturn(): string {
        return 'assets/icons/return-icon.png';
    }

    private showToast(
        type: NbComponentStatus,
        title: string,
        body: string = null,
        ops: object = null
    ) {
        const titleContent = title ? title : '';
        const config = Object.assign(
            {
                status: type,
                destroyByClick: true,
                duration: 5000,
                hasIcon: true,
                position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
                preventDuplicates: false,
            },
            ops
        );
        this.toastService.show(body, titleContent, config);
    }

    connectionWarning() {
        this.showToast('danger', '¡Sin conexión a internet!');
    }
}
