/**
 * @class UpdateEmailComponent
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2024 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */

import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    NbComponentStatus,
    NbGlobalPhysicalPosition,
    NbToastrService,
} from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { NGXLogger } from 'ngx-logger';
@Component({
    selector: 'app-custom-update-email',
    templateUrl: './updateEmail.component.html',
    styleUrls: ['./updateEmail.component.css'],
})
export class UpdateEmailComponent implements OnInit, AfterViewInit {
    isLoading: boolean = false;
    message: string | null = null;
    user: any;
    token: string | null;
    email: string | null;
    jwtRequest: string | null;

    constructor(
    private ngZone: NgZone, // eslint-disable-line
    private route: ActivatedRoute, // eslint-disable-line
    private toastrService: NbToastrService, // eslint-disable-line
    private router: Router, // eslint-disable-line
    private logger: NGXLogger // eslint-disable-line
    ) {
        this.logger.info(
            'Inicializando componente para confirmar actualización de correo electrónico.'
        );
        this.route.queryParamMap.subscribe((query: any) => {
            this.token = query['params'].token;
            this.email = query['params'].email;
        });

        if (!this.token) {
            this.logger.error('No existe el token en los query params!');
            this.redirect();
        }

        try {
            this.user = localStorage.getItem('user');
            localStorage.clear();
        } catch (err) {
            this.logger.error('Ocurrió un error limpiando el localStorage');
        }
    }

    ngOnInit() {
        if (this.token) {
            this.logger.info('Token encontrado en los query params!');
            try {
                this.updateEmail(this.email, this.token);
            } catch (err) {
                this.logger.error(
                    'Ocurrió un error validando del token recibido por query params.',
                    err
                );
            }
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            // console.log(this.jwtRequest);
        }, 1000);
    }

    /**
     * Redirige al inicio
     * @method redirect
     */
    redirect(): void {
        this.ngZone.run(() => {
            localStorage.clear();
            this.router.navigate(['/pages/map']);
        });
    }

    /**
     * Realiza la petición para actualizar el email del usuario
     *@method  updateEmail
     */
    updateEmail(email: string, token: string) {
        fetch(`${environment.pgrst_api}/rpc/update_user_email`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, token }),
        })
            .then(async (res) => {
                if (res.status === 200) {
                    setTimeout(() => {
                        this.redirect();
                    }, 5000);
                    return await res.json();
                } else {
                    this.showToast(
                        'warning',
                        'Ocurrió un error actualizando tu correo electrónico.'
                    );
                    setTimeout(() => {
                        this.redirect();
                    }, 5000);
                    return;
                }
            })
            .then(async (response) => {
                this.user = await response;
                if (this.user === 'invalid user') {
                    this.message =
                        'Ya ha sido confirmada la solicitud para actualizar tu email.';
                    this.showToast(
                        'warning',
                        'Ya ha sido confirmada la solicitud para actualizar tu email.'
                    );
                } else {
                    this.message = this.user;
                    this.showToast(
                        'success',
                        '¡Tu correo electrónico ha sido actualizado correctamente!'
                    );
                }
            })
            .catch((err) => {
                this.logger.error(
                    'Ocurrió un error validando el token para actualizar contraseña.',
                    err
                );
            });
    }

    /**
     * Muestra un toast
     * @method showToast
     * @param type NbComponentStatus
     * @param title string
     * @param body string
     */
    private showToast(
        type: NbComponentStatus,
        title: string,
        body: string = null,
        ops: object = null
    ) {
        const titleContent = title ? title : '';
        const config = Object.assign(
            {
                status: type,
                destroyByClick: true,
                duration: 5000,
                hasIcon: true,
                position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
                preventDuplicates: false,
            },
            ops
        );

        this.toastrService.show(body, titleContent, config);
    }
}
