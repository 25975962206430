/**
 * @class HardinessService
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2024 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ConnectionService } from './connection.service';
import { DialogComponent } from '../../@theme/components/templates/dialog/dialog.component';

declare const emitter: any;

@Injectable({
    providedIn: 'root',
})
export class HardinessService {
    dialogRef: NbDialogRef<any>;

    status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    tests: number = 0;

    isError: boolean = false;

    errorCounter: number = 0;

    emitterInstance: any;

    tag: string = 'Hardiness service';

    internet: boolean = false;

    emitter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    api: boolean = true;

    emitterMsg = false;

    hardinessStatus: BehaviorSubject<any> = new BehaviorSubject<any>({});

    constructor(
    private connection: ConnectionService, // eslint-disable-line
    private logger: NGXLogger, // eslint-disable-line
    private dialogService: NbDialogService // eslint-disable-line
    ) {
        setInterval(async () => {
            try {
                this.tests = 0;
                this.emitterTest();
                await this.pingApi();
                await this.pingConnection();
                await this.emitterMsgTest();
                this.isError = false;
                this.errorCounter = 0;
                this.logger.info(this.tag, 'Test connection successfully!');
                this.status.next(true);
            } catch (err) {
                this.logger.error(
                    'Ocurrió un error realizando test de conexión: ',
                    err
                );
                this.isError = true;
                this.errorCounter++;
            } finally {
                if (this.errorCounter === 1) {
          return; // eslint-disable-line
                }
                if (this.errorCounter === 2 && this.isError) {
                    this.status.next(false);
                    this.openDialog();
                }
            }
        }, 1000 * 15);
    }

    pingConnection(): Promise<boolean> {
        const connection = this.connection.networkStatus.getValue();
        return new Promise((res, rej) => {
            if (connection) {
                res(true);
            } else {
                this.logger.error(
                    'There was an error testing internet conection!'
                );
                rej(false);
            }
        });
    }

    pingApi(): Promise<boolean> {
        const api = this.connection.apiStatus.getValue();
        return new Promise((res, rej) => {
            if (api !== '') {
                res(true);
            } else {
                this.logger.error('There was an error testing API conection!');
                rej(false);
            }
        });
    }

    emitterTest(): void {
        if (this.emitterInstance) {
            this.emitterInstance.disconnect();
        }
        this.emitterInstance = emitter.connect({
            host: environment.api_emitter_host,
            port: environment.api_emitter_port,
            secure: true,
        });

        this.emitterInstance.on('connect', () => {
            this.emitter.next(true);
        });
        this.emitterInstance.on('disconnect', () => {
            this.emitter.next(false);
        });
    }

    emitterMsgTest(): Promise<boolean> {
        return new Promise((res, rej) => {
            if (!this.emitterInstance) {
                rej(false);
            } else {
                this.emitterInstance.on('message', () => {
                    res(true);
                });
                this.emitterInstance.on('error', (err: any) => {
                    this.logger.error(
                        'There was an error while testing emitter sending messages: ',
                        err
                    );
                    rej(false);
                });

                this.emitterInstance.keygen({
                    key: environment.api_emitter_test_key,
                    channel: environment.api_emitter_test_channel,
                    type: 'rwlsp',
                    ttl: 0,
                });
                this.emitterInstance.on('keygen', (res: any) => {
                    if (res && res.status === 200) {
                        this.emitterInstance.subscribe({
                            key: res.key,
                            channel: res.channel,
                        });
                        this.emitterInstance.publish({
                            key: res.key,
                            channel: res.channel,
                            message: 'hello, emitter!',
                        });
                    }
                });
            }
        });
    }

    openDialog() {
        this.dialogRef = this.dialogService.open(DialogComponent, {
            hasScroll: true,
        });
        this.closeDialog();
        this.dialogRef.onClose.subscribe(() => {
            this.logger.info('Modal cerrada!');
        });
    }

    closeDialog() {
        const button = document.getElementById('modal-id');
        button.addEventListener('click', () => {
            this.dialogRef.close();
        });
    }
}
