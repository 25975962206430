<div class="landing">
    <div class="base">

        <div class="img-container">
            <img id="trazappQRImg" class="qr-code" alt="qr-code-trazapp" *ngIf="!isFailed && !isLoading"/>
            <div *ngIf="isFailed" style="text-align: center;">
                <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
            </div>
            <div *ngIf="isLoading" style="text-align: center;">
                <svg class="spinner mobile-loading" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
            </div>
        </div>


        <div class="container-element-mobile" (click)="refreshQR()">
            <a class="cta color-link-qr"  target="_blank" *ngIf="!isLoading">
                Generar nuevo QR
            </a>
        </div>


        <div class="image">
            <img [src]="iconTrazapp()" class="img-trazapp"/>
        </div>
    </div>


    <div class="instructions">
		<div class="image-mobile">
            <img [src]="iconTrazapp()" class="img-trazapp"/>
        </div>
        <ul>
            <li>Abre la aplicación Trazapp en tu dispositivo móvil.</li>
            <li>Busca la opción "Escanear".</li>
            <li>Haz clic en esa opción y se abrirá la cámara de tu dispositivo.</li>
            <li>Escanea el código QR.</li>
            <li>Espera unos segundos mientras se realiza la sincronización.</li>
            <li>Una vez completado el proceso, recibirás una notificación confirmando la sincronización exitosa.</li>
        </ul>
    </div>
	<div class="download-container">
		<p style="font-weight: 600;">¿No tienes Trazapp? ¡Descárgala aquí!</p>
		<a href="https://play.google.com/store/apps/details?id=app.traza.flutter" target="_blank" class="playstore-link">
			<img src="assets/images/playstore.png" alt="Google Play" class="playstore-icon">
			<span style="font-weight: 600;">Trazapp</span>
		</a>
	</div>
	
</div>