/**
 * @class EmitterService
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2024 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */

import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { ElementRef, ViewChild } from '@angular/core';
import * as UAParser from 'ua-parser-js';
import { EmitterSubscribeData } from '../data/emitter';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { ConnectionService } from './connection.service';
import { ToastService } from './toast.service';
import { DialogComponent } from '../../@theme/components/templates/sync-dialog/dialog.component';
declare const emitter: any;

@Injectable({
    providedIn: 'root',
})
export class EmitterService implements OnDestroy {
    tag: string = 'Emitter service';

    onMessage: boolean = false;

    subscription: Subscription;
    //Detecta si hay un query param en la URL
    jwt: boolean = false;
    /**
     * Clave de emitter
     */
    key: string;

    /**
     * Canal para emitter
     */
    channel: string;
    /**
     * Instancia de emitter
     */
    emitterInstance: any;
    /**
     * Cantidad de reintentos fallidos seguidos
     */
    retry = 1;
    /**
     * dirección a subscribir
     */
    path: string;
    /**
     * Contiene el último reporte recibido
     */
    public subject: BehaviorSubject<JSON> = new BehaviorSubject<JSON>(
        JSON.parse('{}')
    );
    /**
     * Variable (flag) que indica si hay una autenticación en progreso
     */
    private authenticating = false;

    emitterStatus: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    dialogRef: NbDialogRef<any>;
    private _timeoutRef: any;
    topicname: any;
    msg: any = null;
    isConnected: boolean = false;
    event: any;
    currentUrl: string;
    @ViewChild('msglog', { static: true }) msglog: ElementRef;

    constructor(
    private router: Router, // eslint-disable-line
    private ngZone: NgZone, // eslint-disable-line
    private connection: ConnectionService, // eslint-disable-line
    private logger: NGXLogger, // eslint-disable-line
    private toast: ToastService, // eslint-disable-line
    private dialogService: NbDialogService, // eslint-disable-line
    private activatedRoute: ActivatedRoute // eslint-disable-line
    ) {
        const tag = 'Emitter service';
        this.logger.info(`${tag} init`);

        this.activatedRoute.queryParamMap.subscribe((query: any) => {
            this.jwt = query['params'].jwt ? true : false;
            this.currentUrl = query['params'].urlCb;
        });

        //Singleton, de existir instancia del emitter corta la ejecución.
        if (this.emitterInstance) return this.emitterInstance;

        //Conexión al servicio de emitter
        this.emitterInstance = emitter.connect({
            host: environment.api_emitter_host,
            port: environment.api_emitter_port,
            secure: true,
            keepAlive: environment.keepAlive,
        });
        this.emitterInstance.on('connect', () => {
            this.logger.info(this.tag, ', Emitter conection succesfully!');
        });
        this.emitterInstance.on('disconnect', () => {
            this.emitterStatus.next(0);
        });

        //Subscripción al servicio de status de internet,
        //de no reportar conexión, arroja error en el código QR
        this.connection.networkStatus.subscribe((e) => {
            if (!e) {
                this.syncFailed();
            }
        });
        this.listenMessage();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Realiza la peticion al api-emitter para la
     * creacion del canal en el broker
     *
     * @param {string} token jwt token de la sesión
     * @param {EmitterSubscribeData} obj objeto con la la key y el canal a subscribirse
     */
    channelConnect(token: string, obj: EmitterSubscribeData) {
        const tag = 'Channel Connect';

        const { key, channel } = obj;

        this.channel = channel;
        this.key = key;

        if (!token || !key || !channel) return;

        // Si hay una autenticación en proceso se descarta cualquier acción
        // se inicia un timout para la operación de autenticación
        if (this.authenticating) {
            this.logger.warn(tag, 'authentication in progress');
            if (!this._timeoutRef) {
                this._timeoutRef = setTimeout(() => {
                    this.authenticating = false;
                    this._timeoutRef = null;
                }, 1000 * 10);
            }
            return;
        }

        this.authenticating = true;

        try {
            clearTimeout(this._timeoutRef);
            if (!this.channel) {
                this.emitterStatus.next(0);
                this.logger.warn('Not channels found!');
            } else {
                this.emitterStatus.next(1);
                return this.subscribe({
                    key: this.key,
                    channel: this.channel,
                });
            }
        } catch (error) {
            this.emitterStatus.next(0);
            this.logger.error(tag, error.message);
        }
    }

    /**
     * Obtiene el key para determinado channel
     * @param {EmitterSubscribeData} req objeto con key y canal para realizar la subscripción a emitter
     * @returns Promise
     */
    subscribe(req: any) {
        const { key, channel } = req;
        const channelAddress: string = channel.includes('#')
            ? channel.slice(0, channel.length - 3)
            : channel;
        const options = [];
        if (req.last != null) {
            options.push({ key: 'last', value: req.last.toString() });
        }
        try {
            this.emitterInstance.subscribe({
                key: key,
                channel: channelAddress,
                last: 0,
            });
            this.emitterStatus.next(1);
            this.logger.info(this.tag, ', Channel subscribe succesfully!!!');
        } catch (err) {
            this.logger.error(this.tag, 'There was an error subscribing', err);
        }
    }

    openDialog(isLibrary: boolean) {
        this.dialogRef = this.dialogService.open(DialogComponent, {
            hasScroll: true,
        });
        this.closeDialog();
        this.dialogRef.onClose.subscribe(() => {
            this.logger.info('Modal cerrada en componente sync!');
            this.toast.success(
                '¡Dispositivo sincronizado! Redirigiendo a página principal...',
                null
            );
            if (isLibrary) {
                setTimeout(() => {
                    window.close();
                }, 3000);
            }
            this.redirect();
        });
    }

    closeDialog() {
        const button = document.getElementById('sync-dialog');
        button.addEventListener('click', () => {
            this.dialogRef.close();
        });
    }

    /**
     * Método para setear en la instancia, el último mensaje recibido del emitter.
     * Se encarga a su vez, se redirigir si la sincronización fue exitosa.
     */
    listenMessage() {
        this.emitterInstance.on('message', (msg: any) => {
            const message = JSON.parse(msg.asString());
            this.msg = message;
            if (this.jwt) {
                this.logger.info('Sending sync message to origin window!');
                window.opener.postMessage(this.msg, this.currentUrl);
                this.syncFinished();
                this.openDialog(true);
            }
            if (
                !message.lat &&
                !message.lon &&
                !message['cod_edo'] &&
                !message.ubicacion
            ) {
                if (this.router.url === '/sync') {
                    if (message.lat || message.lon || message['cod_edo'])
                        return;
                    this.syncFinished();
                    this.openDialog(false);
                } else {
                    return;
                }
            }
            this.emitterStatus.next(1);
            this.subject.next(message);
            this.onMessage = true;
            this.logger.info(this.tag, message, 'Emitter message!!!');
        });
    }

    /**
     * Método que se encarga de redirigir al home
     */
    redirect() {
        setTimeout(() => {
            this.ngZone.run(() => {
                if (this.router.url !== '/pages/map')
                    this.router.navigate(['/pages/map']);
            });
        }, 5500);
    }

    /**
     * Obtiene los datos del dispositivo
     */
    getDevicesInfo() {
        const uap = new UAParser();
        const uainfo = uap.getResult();
        return {
            uuid: btoa(uainfo.ua),
            vendor: uainfo.browser.name,
            model: uainfo.browser.version,
            platform: uainfo.os.name,
        };
    }

    /**
     * Método que confirma la sincronización
     * @param {string} commingJwt jwt, de no recibirlo, toma el que debe estar alojado en el localStorage
     */
    deviceSyncConfirm(commingJwt: string) {
        const me = this;
        const jwt = commingJwt
            ? commingJwt
            : localStorage.getItem('jwt-trazapp');

        this.logger.info(
            `request to [${environment.pgrst_api}/rpc/device_sync_confirm]...`
        );
        fetch(`${environment.pgrst_api}/rpc/device_sync_confirm`, {
            method: 'POST',
            body: JSON.stringify({
                _in_device_info: me.getDevicesInfo(),
            }),
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
            },
        })
            .then((response) => response.json())
            .then((json) => {
                this.logger.info(`Device sync confirm ${this.tag}`, json);
            })
            .catch((ex) => {
                this.logger.error(ex.message);
            });
    }

    /**
     * Ejecuta las acción de sincronoización finalizada
     */
    syncFinished(): void {
        const audio = new Audio('assets/audio/complete.ogg');
        const imgElement = document.getElementById(
            'trazappQRImg'
        ) as HTMLImageElement;
        if (imgElement) {
            imgElement.src = 'assets/icons/finished.png';
            imgElement.classList.remove('qr-code');
            imgElement.classList.add('qr-code-without-border');
            imgElement.classList.add('flip-animation');
        }
        const playAudio = () => {
            audio.play();
        };
        playAudio();
    }

    /**
     * Cambia la imagen del QR por una que hace referencia a falla,
     * además, ejecuta sonido referente.
     */

    syncFailed(): void {
        const audio = new Audio('assets/audio/failed.ogg');
        const imgElement = document.getElementById(
            'trazappQRImg'
        ) as HTMLImageElement;
        if (!audio || !imgElement) return;
        if (imgElement) {
            imgElement.src = 'assets/icons/sync_failed.png';
            imgElement.classList.remove('qr-code');
            imgElement.classList.add('qr-code-without-border');
            imgElement.classList.add('flip-animation');
        }
        const playAudio = () => {
            audio.play();
        };
        playAudio();
    }

    /**
     * Método para desconectar el emitter en los casos
     * en que no se perciba conexión a internet
     */
    disconnect() {
        this.logger.info(this.tag, 'disconnect!');
        this.emitterStatus.next(0);
    }
}
