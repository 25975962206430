/**
 * @class UpdatePasswordComponent
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2024 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */
import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
} from '@angular/forms';
import {
    NbComponentStatus,
    NbGlobalPhysicalPosition,
    NbToastrService,
} from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { EmitterService } from '../../../@core/mock/emitter.service';
import { NGXLogger } from 'ngx-logger';
import {
    messageError,
    passwordValue,
} from '../../../@core/data/updatePassword';
@Component({
    selector: 'app-custom-update-password',
    templateUrl: './updatePassword.component.html',
    styleUrls: ['./updatePassword.component.css'],
})
export class UpdatePasswordComponent implements OnInit, AfterViewInit {
    isLoading: boolean = false;
    user: any;
    updatePasswordForm: FormGroup;
    token: string | null;
    jwtRequest: string | null;

    constructor(
    private activatedRoute: ActivatedRoute, // eslint-disable-line
    private emitter: EmitterService, // eslint-disable-line
    private ngZone: NgZone, // eslint-disable-line
    private route: ActivatedRoute, // eslint-disable-line
    private formBuilder: FormBuilder, // eslint-disable-line
    private toastrService: NbToastrService, // eslint-disable-line
    private router: Router, // eslint-disable-line
    private logger: NGXLogger // eslint-disable-line
    ) {
        this.logger.info(
            'Inicializando formulario para actualización de contraseña.'
        );
        this.route.queryParamMap.subscribe((query: any) => {
            this.token = query['params'].token;
        });

        if (!this.token) {
            this.logger.error('No existe el token en los query params!');
            this.redirect();
        }

        try {
            this.user = localStorage.getItem('user');
            localStorage.clear();
        } catch (err) {
            this.logger.error('Ocurrió un error limpiando el localStorage');
        }
        this.updatePasswordForm = this.formBuilder.group({
            newpassword: [''],
            confirmpassword: [''],
        });
    }

    ngOnInit() {
        if (this.token) {
            this.logger.info('Token encontrado en los query params!');
            try {
                this.validateToken(this.token);
            } catch (err) {
                this.logger.error(
                    'Ocurrió un error validando del token recibido por query params.',
                    err
                );
            }
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            // console.log(this.jwtRequest);
        }, 1000);
    }

    /**
     * Redirige al inicio
     * @method redirect
     */
    redirect(): void {
        this.ngZone.run(() => {
            this.router.navigate(['/pages/map']);
        });
    }
    /**
     * Confirma y valida los cambios en los datos del usuario
     * @method  confirmChanges
     * @param value { password, repeatPassword }
     */
    async confirmChanges(value: passwordValue) {
        if (!value.newpassword || !value.confirmpassword) {
            this.showToast(
                'warning',
                'Debes ingresar la contraseña y la confimación de la misma.'
            );
        }
        let data = {
            newpassword: value.newpassword,
            confirmpassword: value.confirmpassword,
        };

        if (this.validateData(data) === true) {
            try {
                if (!this.jwtRequest)
                    throw new Error(
                        'No existe el JWT para realizar la petición!'
                    );
                this.logger.warn('Modificando contraseña del usuario...');
                fetch(`${environment.pgrst_api}/rpc/update_password`, {
                    method: 'POST',
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.jwtRequest}`,
                    },
                    body: JSON.stringify(data),
                })
                    .then(async (res) => {
                        if (res.status === 200) {
                            this.showToast(
                                'success',
                                'Contraseña restablecida exitósamente.'
                            );
                            setTimeout(() => {
                                this.redirect();
                            }, 3000);
                            return await res.json();
                        } else {
                            throw new Error('Ups! Algo salió mal...');
                        }
                    })
                    .catch((err) => {
                        this.logger.error(
                            'Ocurrió un error validando el token para actualizar contraseña.',
                            err
                        );
                    });
            } catch (err) {
                this.logger.error(
                    'Ocurrió un error modificando los datos del usuario.'
                );
                this.showToast(
                    'danger',
                    'Ups! Ocurrió un error...',
                    'Ocurrió un error modificando tus datos, por favor, inténtalo nuevamente.'
                );
            }
        } else {
            const error: any = this.validateData(data);
            this.logger.warn(error.error);
            this.showToast('danger', error.msg);
        }
    }

    /**
     * Valida que el token recibido por PARAMS sea valido
     *@method  validateToken
     */
    validateToken(_token_api: string) {
        const me = this;
        fetch(`${environment.pgrst_api}/rpc/update_password_token`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ _token_api }),
        })
            .then(async (res) => {
                if (res.status === 200) {
                    return await res.json();
                } else {
                    this.showToast(
                        'warning',
                        'La solicitud para actualización de contraseña se encuentra caducada.'
                    );
                    setTimeout(() => {
                        this.redirect();
                    }, 3000);
                    return;
                }
            })
            .then(async (response) => {
                me.jwtRequest = await response;
            })
            .catch((err) => {
                this.logger.error(
                    'Ocurrió un error validando el token para actualizar contraseña.',
                    err
                );
            });
    }

    /**
     * Valida que la contraseña y su confirmación sean estríctamente iguales,
     * Valida que los campos no estén vacíos
     * Valida que no existan espacios en los campos
     * @method  validateData
     * @param data objeto de tipo passwordValue que contendrá las propiedades {newpassword, confirmpassword}
     */
    validateData(data: passwordValue): boolean | messageError {
        if (data.newpassword !== data.confirmpassword)
            return {
                msg: 'La contraseña y su confirmación no son totalmente iguales.',
                error: 'Error en la contraseña.',
            };
        if (
            data.newpassword.includes(' ') ||
            data.confirmpassword.includes(' ')
        )
            return {
                msg: 'La contraseña no puede contener espacios.',
                error: 'Error en la contraseña.',
            };
        if (!data.newpassword.length || !data.confirmpassword.length)
            return {
                msg: 'Debes ingresar la nueva contraseña y su respectiva confirmación.',
                error: 'Error en la contraseña.',
            };
        return true;
    }

    /**
     * Elimina todos los cambios que se iban a realizar
     * @method  cancelChanges
     */
    cancelChanges(): void {
        this.logger.warn('Modificación de datos del usuario cancelada.');
        this.updatePasswordForm = this.formBuilder.group({
            password: [''],
            repeatPassword: [''],
        });
        this.showToast('warning', 'Edición de datos cancelada.');
    }

    /**
     * Método recursiva que se encarga de evaluar los errores en el formulario del login,
     * Retorna un arreglo con dichos errores, de no existir, retorna un arreglo vacío
     * @method getFormErrors
     * @param control
     * @param formGroupName
     * @param fieldName
     * @param errors
     */
    getFormErrors(
        control: AbstractControl,
        formGroupName: string,
        fieldName: string,
        errors: any[]
    ) {
        if (control instanceof FormGroup) {
            Object.keys(control.controls).forEach((controlName) => {
                let formControl = control.get(controlName);
                if (formControl) {
                    let fGroupName = formGroupName + '-' + controlName;
                    this.getFormErrors(
                        formControl,
                        fGroupName,
                        controlName,
                        errors
                    );
                }
            });
        }

        if (control instanceof FormArray) {
            control.controls.forEach((fControl: AbstractControl, index) => {
                let fGroupName = formGroupName + '-' + index;
                this.getFormErrors(fControl, fGroupName, fieldName, errors);
            });
        }

        if (control.errors) {
            Object.keys(control.errors).forEach((errorCode) => {
                let title = fieldName;

                errors.push({
                    formGroupName: formGroupName,
                    fieldName: fieldName,
                    errorCode: errorCode,
                });

                switch (fieldName) {
                    case 'email':
                        fieldName = 'Correo';
                        break;

                    case 'password':
                        fieldName = 'Clave de usuario';
                        break;

                    default:
                        break;
                }

                switch (errorCode) {
                    case 'required':
                        title = `${fieldName} es requerido!`;
                        break;

                    case 'email':
                        title = 'Formato de correo invalido!';
                        break;

                    default:
                        break;
                }

                this.showToast('warning', title, '', { duration: 1000 * 5 });
            });
        }
    }

    /**
     * Muestra un toast
     * @method showToast
     * @param type NbComponentStatus
     * @param title string
     * @param body string
     */
    private showToast(
        type: NbComponentStatus,
        title: string,
        body: string = null,
        ops: object = null
    ) {
        const titleContent = title ? title : '';
        const config = Object.assign(
            {
                status: type,
                destroyByClick: true,
                duration: 5000,
                hasIcon: true,
                position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
                preventDuplicates: false,
            },
            ops
        );

        this.toastrService.show(body, titleContent, config);
    }
}
