import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    hardinessStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() {}

    setLoader(loading: boolean): void {
        this.hardinessStatus.next(loading);
    }
}
