/**
 * @class DialogComponent
 * @module class
 * @author nquinones <nestor.quinones@sigis.com.ve>
 * @copyright (c) 2024 Copyright SIGIS Soluciones Integrales GIS, C.A.
 */

import { Component } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
@Component({
    selector: 'nb-dialog-template',
    template: `
        <div class="container">
            <div class="info">
                <h1><b>Habilitar permisos en el app móvil</b></h1>
                <ul>
                    <li>
                        Para que la aplicación móvil funcione correctamente, es
                        importante habilitar todos los permisos solicitados,
                        específicamente el acceso a ubicación todo el tiempo.
                    </li>
                    <li>
                        Recuerda que habilitar los permisos es importante para
                        garantizar un correcto funcionamiento de la aplicación y
                        acceder a todas sus características.
                    </li>
                </ul>
            </div>
            <button id="sync-dialog">Cerrar</button>
        </div>
    `,
    styleUrls: ['./dialog.css'],
})
export class DialogComponent {
    dialogRef: NbDialogRef<any>;
  constructor(private dialogService: NbDialogService) {}  // eslint-disable-line
}
